<template>
  <div id="invoice">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Invoice") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="invoice.invoice_status && invoice.invoice_status != 'deleted'">
      <v-col
        cols="12"
        md="12"
        class="text-center print-col"
        v-if="printBtnVisible"
      >
        <v-icon
          color="#7297FF"
          class="text-center"
          large
          @click="printPage"
          :title="$t('Print')"
          >print</v-icon
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" class="info-list">
        <v-row>
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Name") }}:</strong>
              {{ applicant_details.applicant_name }}
            </p></v-col
          >
          <v-col cols="6" md="2"
            ><p class="value">
              <strong>{{ $t("Partner Group") }}:</strong>
              {{ $t(applicant_details.partner_group) }}
            </p></v-col
          >
          <v-col cols="6" md="2"
            ><p class="value">
              <strong>{{ $t("Grade") }}:</strong>
              {{ applicant_details.grade }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("ID") }}:</strong>
              {{ applicant_details.applicant_code }}
            </p></v-col
          >
          <v-col cols="6" md="2"
            ><p class="value">
              <strong>{{ $t("Invoice Number") }}:</strong>
              {{ invoice.invoice_number }}
            </p></v-col
          >
        </v-row>
      </v-col>
      <v-col cols="12" md="2" class="paymentBtn text-right">
        <v-btn
          v-if="
            invoice.invoice_status == 'issued' &&
              createPaymentBtnVisible &&
              hasPayment == false
          "
          @click="goToPaymentPage"
          color="#7297FF"
          >{{ $t("Create Payment") }}</v-btn
        >
      </v-col>
    </v-row>
    <hr class="basic" />

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="items"
          :items-per-page="5"
          class="elevation-1 level1"
          loading-text="Loading... Please wait"
          hide-default-footer
        >
          <template v-slot:item.counter="{ item }">
            <span>{{ items.indexOf(item) + 1 }}</span>
          </template>
          <template v-slot:item.due_date="{ item }">
            <v-icon
              v-if="item.type != 'tax'"
              color="#ff8972"
              class="calendar-icon"
              >calendar_today</v-icon
            >
            <span class="due-date">{{ item.due_date }}</span>
          </template>
          <template v-slot:item.due_amount="{ item }">
            <span v-if="item.type == 'tax'">{{ item.due_amount }}%</span>
            <span v-if="item.type != 'tax'">{{ item.due_amount }}</span>
          </template>

          <template v-slot:item.amount="{ item }">
            <span v-if="item.type == 'tax'">{{
              calculateTax(item.due_amount)
            }}</span>
            <span v-if="item.type != 'tax'">
              {{ item.amount }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="#7297FF"
              v-if="item.type != 'tax' && invoice.invoice_status == 'draft'"
              :title="$t('Delete')"
              @click="
                deleteInvoiceItem(
                  item.id,
                  item.item,
                  item.item_id,
                  item.relation_type
                )
              "
              >delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-icon
          v-if="invoice.invoice_status == 'draft'"
          color="#7297FF"
          large
          @click="openCollectCashDialog(applicant_details.applicant_id)"
          >add_circle</v-icon
        >
      </v-col>
    </v-row>

    <hr class="second" />

    <v-row>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ totalAmountBeforTax }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong> {{ taxAmount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ total }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ invoice.total_after_discount }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-right">
        <v-btn
          color="#7297FF"
          @click="saveIssued"
          v-if="invoice.invoice_status == 'draft'"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Issued") }}</v-btn
        >
        <v-btn
          color="#7297FF"
          v-if="!invoice.invoice_status"
          @click="saveInvoice"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save Invoice") }}</v-btn
        >
      </v-col>
    </v-row>

    <!-- collect cash component -->
    <CollectCashComponent
      :collectCashDialog="collectCashDialog"
      :applicant_id="applicant_details.applicant_id"
      :selected_Items="selected_Items"
      :extraSelected_Items="extraSelected_Items"
      :invoice_id="invoice_id"
      :tableData="items"
      v-on:childToParent="closeCollectCashDialog"
    >
    </CollectCashComponent>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      {{ snackText }}
      <v-btn color="pink" text @click="snackbar = false">{{
        $t("Close")
      }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import CollectCashComponent from "../../components/modals/CollectCashComponent";
import ACL from "../../acl";
export default {
  name: "Invoice",
  components: { CollectCashComponent },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "counter",
        },
        { text: this.$i18n.t("Fee Type"), value: "item", sortable: false },
        {
          text: this.$i18n.t("Due Amount"),
          value: "due_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Due Date"), value: "due_date", sortable: false },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        { text: this.$i18n.t("Amount"), value: "amount", sortable: false },
        { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      options: {
        itemsPerPage: 100,
      },
      // taxPercentage: "",
      invoice_id: "",
      applicant_details: {},
      invoice: {},
      selected_Items: [],
      extraSelected_Items: [],
      collectCashDialog: false,
      invoiceData: {},
      count: 0,
      snack: false,
      snackColor: "",
      snackText: "",
      snackbar: false,
      hasPayment: false,
      printBtnVisible: ACL.checkPermission("print-invoices"),
      createPaymentBtnVisible: ACL.checkPermission("create-payment"),
    };
  },
  computed: {
    totalAmountBeforTax: {
      get() {
        let num = 0;
        this.items.forEach((item) => {
          if (item.type == "item") {
            num += Number(item.due_amount);
          }
        });

        return num;
      },
    },
    totalAmountForTaxable: {
      get() {
        let num = 0;
        this.items.forEach((item) => {
          if (
            item.type == "item" &&
            (item.taxable == 1 || item.taxable == true)
          ) {
            num += Number(item.due_amount);
          }
        });

        return num;
      },
      set() {
        this.invoiceData.invoice.total_before_tax = this.totalAmountBeforTax;
      },
    },
    taxPercentage: {
      get() {
        let tax = 0;
        this.items.forEach((item) => {
          if (item.type == "tax") {
            tax += Number(item.due_amount);
          }
        });

        return tax;
      },
      set() {},
    },
    taxAmount: {
      get() {
        return (this.taxPercentage * this.totalAmountForTaxable) / 100;
      },
    },
    total: {
      get() {
        return (
          this.totalAmountBeforTax +
          (this.taxPercentage * this.totalAmountForTaxable) / 100
        );
      },
    },
  },
  methods: {
    calculateTax(percentage) {
      return (percentage * this.totalAmountForTaxable) / 100;
    },

    closeCollectCashDialog(value, selectedItems, extraSelectedItems) {
      if (value) {
        this.items = value;
        this.invoiceData.items = value;
      }
      this.selected_Items = selectedItems;
      this.extraSelected_Items = extraSelectedItems;
      this.collectCashDialog = false;
    },
    openCollectCashDialog(applicantId) {
      this.applicant_id = applicantId;
      this.collectCashDialog = true;
    },
    getPaymentInvoiceItems() {
      this.selected_Items = [];
      axios
        .get(this.getApiUrl + "/invoices/getInvoiceData/" + this.invoice_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.invoiceData = response.data.data;
          this.items = response.data.data.items;
          this.applicant_details = response.data.data.applicant_datails;
          this.invoice = response.data.data.invoice;

          this.items.forEach((item) => {
            if (this.selected_Items.includes(item.item_id) == false) {
              if (item.type == "tax") {
                this.taxPercentage = item.due_amount;
              } else if (
                item.type == "item" &&
                item.relation_type == "feeplan"
              ) {
                this.selected_Items.push(item.item_id);
              } else if (item.type == "item" && item.relation_type == "item") {
                this.extraSelected_Items.push(item.item_id);
              }
            }
          });
        });
    },

    saveInvoice() {
      this.invoiceData.invoice.total_before_tax = this.totalAmountBeforTax;
      this.invoiceData.invoice.total = this.total;
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/invoices/updateInvoice/" + this.invoice_id,
          this.invoiceData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          this.items = response.data.data.items;
          this.invoiceData = response.data.data;
          this.applicant_details = response.data.data.applicant_datails;
          this.invoice = response.data.data.invoice;

          this.items.forEach((item) => {
            if (this.selected_Items.includes(item.item_id) == false) {
              if (item.type == "tax") {
                this.taxPercentage = item.due_amount;
              } else {
                this.selected_Items.push(item.item_id);
              }
            }
          });
        });
    },

    saveIssued() {
      this.invoiceData.invoice.total_before_tax = this.totalAmountBeforTax;
      this.invoiceData.invoice.total = this.total;
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/invoices/issueInvoice/" + this.invoice_id,
          this.invoiceData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.invoice.invoice_status =
              response.data.data.invoice.invoice_status;
            location.reload();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
            this.snackbar = true;
          }
        });
    },

    deleteInvoiceItem(id, item, itemId, relation) {
      if (id) {
        axios
          .get(this.getApiUrl + "/invoices/deleteApplicantInvoiceLine/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.items = this.items.filter((item) => {
                return item.item_id != itemId;
              });

              this.invoiceData.items = this.items;

              if (relation == "feeplan") {
                this.selected_Items = this.selected_Items.filter(
                  (selectedId) => {
                    return selectedId != itemId;
                  }
                );
              } else if (relation == "item") {
                this.extraSelected_Items = this.extraSelected_Items.filter(
                  (extraSelectedId) => {
                    return extraSelectedId != itemId;
                  }
                );
              }
            }
          });
      } else {
        this.items = this.items.filter((item) => {
          return item.item_id != itemId;
        });

        this.invoiceData.items = this.items;
        if (relation == "feeplan") {
          this.selected_Items = this.selected_Items.filter((selectedId) => {
            return selectedId != itemId;
          });
        } else if (relation == "item") {
          this.extraSelected_Items = this.extraSelected_Items.filter(
            (extraSelectedId) => {
              return extraSelectedId != itemId;
            }
          );
        }
      }
    },
    printPage() {
      axios
        .get(this.getApiUrl + "/invoices/printPdfInvoice/" + this.invoice_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    goToPaymentPage() {
      this.$router.push({
        path: `/cashcollection/` + this.invoice_id,
      });
    },
    checkPaymentOnInvoice() {
      axios
        .get(
          this.getApiUrl + "/payments/checkPaymentOnInvoice/" + this.invoice_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.data.hasPayment) {
            this.hasPayment = true;
          }
        });
    },
  },
  mounted() {
    this.invoice_id = this.$router.currentRoute.params.invoiceId;
    this.getPaymentInvoiceItems();
    this.checkPaymentOnInvoice();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.info-list {
  padding: 0 12px;
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}
.stats {
  strong {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.title-col {
  padding-bottom: 0;
}
.print-col {
  padding: 0 12px;
}
</style>
